.masonry {
  display: flex;
  transform: translateZ(0);
  box-sizing: border-box;
  opacity: 0;
  background-color: aliceblue;
  justify-content: center;
}

.masonry.show {
    opacity: 1;
}

.column {
  display: inline-block;
  box-sizing: border-box;
  margin-right: 8px;
  flex: 1;
}

.column:last-child {
  margin: 0;
}

.masonry-item {
  width: 100%;
  display: block;
  cursor: pointer;
  margin-bottom: 8px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/* .masonry-item:hover {

} */
